<template>
  <b-modal id="term" ref="term" title="Terms & Conditions" size="lg">
    <b-container fluid class="p-0">
      <b-overlay :show="loading">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-text-editors
                label="Nội dung giới thiệu đầy đủ khi người dùng xem chi tiết"
                placeholder="Nhập nội dung thông báo"
                name="content"
                :required="true"
                :value.sync="form.value"
                :toolbarOptions="['link', 'image', 'video']"
                data-vv-as="Nội dung chi tiết"
                v-validate="'required'"
                :state="validateState('content')"
                :invalidFeedback="errors.first('content')"
              >
              </basic-text-editors>
            </b-col>
          </b-row>
        </form>
      </b-overlay>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Cập nhật
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  props: {
    id: {
      type: String,
      default: null,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      file: null,
      preview: null,
      form: {},
      loading: false,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadData(newVal);
        }
      },
    },
  },
  computed: {},
  methods: {
    async loadData(id) {
      try {
        let { data } = await this.$api.get(`CommonConfigureTask/${id}`);
        this.form = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      }
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      this.loading = true;
      try {
        await this.$api.put('CommonConfigureTask', this.form);
        this.$bvModal.hide('term');
        this.$emit('loadData');
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$bvModal.hide('term');
    },
  },
};
</script>

<style lang="scss" scoped></style>
